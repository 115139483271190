<template>
  <div class="report">
    <div class="content">
      <div class="report__caption">Служба Медицинской Информации</div>
    </div>
    <Breadcrumbs :pages="[{ name: 'Служба Медицинской Информации' }]" />
    <div class="content">
      <div class="report__description">
        Если вас интересует медицинская и научная информация о препаратах
        компании, вы можете отправить запрос на её получение с помощью
        электронной почты
        <!-- веб-формы по передаче запросов -->
        <!-- <a
            href="https://contactazmedical.astrazeneca.com/content/astrazeneca-champion/ru/ru/amp-form.html"
            class="contacts__link-underline"
            >CHAMPion</a
          >, или по электронной почте -->
        <a href="mailto:Medinfo.ru@astrazeneca.com"
          >Medinfo.ru@astrazeneca.com</a
        >
        или по телефону <a href="tel:84957995699">+7&nbsp;(495) 799-56-99</a>.

        <br />
        <br />
        Пожалуйста, обратите внимание, что передавая запрос медицинской
        информации любым вышеуказанным способом, вы признаете, что ознакомились
        с условиями настоящей
        <a href="https://astrazeneca.ru/confidentiality.html"
          >Политики об обработке персональных данных ООО «АстраЗенека
          Фармасьютикалз»</a
        >
        и согласны на обработку ваших персональных данных на указанных в ней
        условиях.
        <br />
        <br />
        <!-- <ul class="contacts__drop drop-list">
          <li class="contacts__drop drop-item">
            Указывая в сообщении ваши персональные данные, вы даёте согласие
            на их обработку компанией «АстраЗенека» с целью предоставления
            ответа на запрос по медицинской информации.
          </li>
          <li class="contacts__drop drop-item">
            Обработка персональных данных включает запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение),
            использование, обезличивание, уничтожение. Предоставленные
            персональные данные будут уничтожены из информационных систем
            компании «АстраЗенека» по достижении цели их обработки.
          </li>
          <li class="contacts__drop drop-item">
            В случае вашего несогласия с обработкой персональных данных
            компанией «АстраЗенека», пожалуйста, отправьте электронное сообщение
            на адрес
            <a href="mailto:Medinfo.ru@astrazeneca.com"
              >Medinfo.ru@astrazeneca.com</a
            >
            с комментарием «Не согласен». С момента получения вашего сообщения,
            компания «АстраЗенека» прекратит обработку ваших персональных данных
            и вашего запроса.
          </li>
        </ul> -->
        По вопросам, касающимся состояния здоровья или рекомендаций, связанных с
        назначением лекарственных препаратов «АстраЗенека», вам следует
        проконсультироваться с лечащим врачом.
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumbs from "../components/pageComponents/Breadcrumbs.vue";
export default {
  metaInfo: {
    title: "Служба Медицинской Информации",
  },
  name: "MedicalInfo",
  components: { Breadcrumbs },
};
</script>

<style lang="scss" scoped>
.report {
  padding-top: 32px;
  @media screen and (max-width: 1220px) {
    padding-top: 40px;
  }
  @media screen and (max-width: 767px) {
    padding-top: 32px;
  }
  &__caption {
    font-family: "Roboto Slab", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 42px;
    line-height: 48px;
    color: #1f1f1f;

    @media screen and (max-width: 767px) {
      font-size: 21px;
      line-height: 26px;
    }
  }

  &__description {
    margin-top: 64px;
    max-width: 800px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 23px;
    color: #3c4242;

    & > a {
      color: #d0006f;
    }

    @media screen and (max-width: 1220px) {
      margin-top: 40px;
    }

    @media screen and (max-width: 767px) {
      margin-top: 32px;
    }
  }

  .breadcrumbs {
    @media screen and (max-width: 767px) {
      padding-top: 16px;
    }
  }
}
</style>

<style lang="scss">
.contacts {
  &__drop {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
    p {
      margin-bottom: 16px;
    }
    a {
      display: inline-block;
      color: #d0006f;
    }
    &.drop-list {
      list-style: disc;
      padding-left: 16px;
    }
    &.drop-item {
      margin-bottom: 16px;
    }
  }
}
</style>
